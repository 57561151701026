<template>
  <div class="login" :class="userStatus">
    <div class="card card-container">
      <div class="company">
        <a href="/">
          <img
            id="profile-img"
            src="https://www.fortify247.net/wp-content/uploads/2020/12/fortify247-header-logo-black.png"
            class="profile-img-card"
          />
        </a>
      </div>
      <h4>Welcome to The Fortify Portal! 👋</h4>
      <p>Enter Your Contact Details</p>
      <Form
        @submit="checkUserStatus"
        :validation-schema="schema"
        v-if="userStatus === 'registered'"
      >
        <div class="form-group">
          <label for="userEmail">Email</label>
          <Field name="userEmail" type="email" class="form-control" />
          <ErrorMessage name="userEmail" class="error-feedback" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Next</span>
          </button>
        </div>
      </Form>
      <Form
        @submit="createAccount"
        :validation-schema="schema"
        :initial-values="formValues"
        v-if="userStatus === 'unregistered'"
      >
        <div class="card">
          <div class="flex-row">
            <div class="col1">
              <div class="inputGroup">
                <label for="customerType">
                  Is this for you or your business?
                </label>
              </div>
            </div>
            <div class="col1">
              <div class="inputGroup">
                <Field name="tags" as="select">
                  <option value="">Choose one</option>
                  <option value="b2c">For Me (B2C)</option>
                  <option value="b2b">For My Business (B2B)</option>
                </Field>
                <ErrorMessage name="tags" class="error-feedback" />
              </div>
            </div>
          </div>
          <div class="flex-row">
            <div class="col1">
              <div class="inputGroup">
                <label for="firstName">First Name:</label>
                <Field name="firstName" type="text" />
                <ErrorMessage name="firstName" class="error-feedback" />
              </div>
              <div class="inputGroup">
                <label for="email">Email:</label>
                <Field name="email" type="email" disabled />
                <ErrorMessage name="email" class="error-feedback" />
                <a href="/pc-repair-ticket"><small>Change Email</small></a>
              </div>
            </div>
            <div class="col1">
              <div class="inputGroup">
                <label for="lastName">Last Name:</label>
                <Field name="lastName" type="text" />
                <ErrorMessage name="lastName" class="error-feedback" />
              </div>
              <div class="inputGroup">
                <label for="phone">Phone Number:</label>
                <Field name="phone" type="text" />
                <ErrorMessage name="phone" class="error-feedback" />
              </div>
            </div>
          </div>
          <label for="city">Your Address:</label>
          <div class="flex-row">
            <div class="col1">
              <div class="inputGroup">
                <Field
                  name="address1"
                  type="text"
                  placeholder="Address Line 1"
                />
                <ErrorMessage name="address1" class="error-feedback" />
              </div>
              <div class="inputGroup">
                <Field name="city" type="text" placeholder="City" />
                <ErrorMessage name="city" class="error-feedback" />
              </div>

              <div class="inputGroup">
                <Field
                  name="postalCode"
                  type="text"
                  placeholder="Postal Code"
                />
                <ErrorMessage name="postalCode" class="error-feedback" />
              </div>
            </div>
            <div class="col1">
              <div class="inputGroup">
                <Field
                  name="address2"
                  type="text"
                  placeholder="Address Line 2"
                />
                <ErrorMessage name="address2" class="error-feedback" />
              </div>
              <div class="inputGroup">
                <Field name="province" as="select">
                  <option value="Aberdeen City">Aberdeen City</option>
                  <option value="Aberdeenshire">Aberdeenshire</option>
                  <option value="Angus">Angus</option>
                  <option value="Antrim">Antrim</option>
                  <option value="Ards">Ards</option>
                  <option value="Argyll and Bute">Argyll and Bute</option>
                  <option value="Armagh">Armagh</option>
                  <option value="Ballymena">Ballymena</option>
                  <option value="Ballymoney">Ballymoney</option>
                  <option value="Banbridge">Banbridge</option>
                  <option value="Barking and Dagenham"
                    >Barking and Dagenham</option
                  >
                  <option value="Barnet">Barnet</option>
                  <option value="Barnsley">Barnsley</option>
                  <option value="Bath and North East Somerset"
                    >Bath and North East Somerset</option
                  >
                  <option value="Bedford">Bedford</option>
                  <option value="Belfast City">Belfast City</option>
                  <option value="Bexley">Bexley</option>
                  <option value="Birmingham">Birmingham</option>
                  <option value="Blackburn with Darwen"
                    >Blackburn with Darwen</option
                  >
                  <option value="Blackpool">Blackpool</option>
                  <option value="Blaenau Gwent">Blaenau Gwent</option>
                  <option value="Bolton">Bolton</option>
                  <option value="Bournemouth">Bournemouth</option>
                  <option value="Bracknell Forest">Bracknell Forest</option>
                  <option value="Bradford">Bradford</option>
                  <option value="Brent">Brent</option>
                  <option value="Bridgend">Bridgend</option>
                  <option value="Bristol City">Bristol City</option>
                  <option value="Bromley">Bromley</option>
                  <option value="Buckinghamshire">Buckinghamshire</option>
                  <option value="Bury">Bury</option>
                  <option value="Caerphilly">Caerphilly</option>
                  <option value="Calderdale">Calderdale</option>
                  <option value="Cambridgeshire">Cambridgeshire</option>
                  <option value="Camden">Camden</option>
                  <option value="Cardiff">Cardiff</option>
                  <option value="Carmarthenshire">Carmarthenshire </option>
                  <option value="Carrickfergus">Carrickfergus</option>
                  <option value="Castlereagh">Castlereagh</option>
                  <option value="Central Bedfordshire"
                    >Central Bedfordshire</option
                  >
                  <option value="Ceredigion">Ceredigion</option>
                  <option value="Cheshire East">Cheshire East</option>
                  <option value="Cheshire West and Chester"
                    >Cheshire West and Chester</option
                  >
                  <option value="Clackmannanshire">Clackmannanshire</option>
                  <option value="Coleraine">Coleraine</option>
                  <option value="Conwy">Conwy</option>
                  <option value="Cookstown">Cookstown</option>
                  <option value="Cornwall">Cornwall</option>
                  <option value="Coventry">Coventry</option>
                  <option value="Craigavon">Craigavon</option>
                  <option value="Croydon">Croydon</option>
                  <option value="Cumbria">Cumbria</option>
                  <option value="Darlington">Darlington</option>
                  <option value="Denbighshire">Denbighshire</option>
                  <option value="Derby">Derby</option>
                  <option value="Derbyshire">Derbyshire</option>
                  <option value="Derry City">Derry City</option>
                  <option value="Devon">Devon</option>
                  <option value="Doncaster">Doncaster</option>
                  <option value="Dorset">Dorset</option>
                  <option value="Down">Down</option>
                  <option value="Dudley">Dudley</option>
                  <option value="Dumfries and Galloway"
                    >Dumfries and Galloway</option
                  >
                  <option value="Dundee City">Dundee City</option>
                  <option value="Dungannon and South Tyrone"
                    >Dungannon and South Tyrone</option
                  >
                  <option value="Durham">Durham</option>
                  <option value="Ealing">Ealing</option>
                  <option value="East Ayrshire">East Ayrshire</option>
                  <option value="East Dunbartonshire"
                    >East Dunbartonshire</option
                  >
                  <option value="East Lothian">East Lothian</option>
                  <option value="East Renfrewshire">East Renfrewshire</option>
                  <option value="East Riding of Yorkshire"
                    >East Riding of Yorkshire</option
                  >
                  <option value="East Sussex">East Sussex</option>
                  <option value="Edinburgh, City of">Edinburgh, City of</option>
                  <option value="Enfield">Enfield</option>
                  <option value="Essex">Essex</option>
                  <option value="Falkirk">Falkirk</option>
                  <option value="Fermanagh">Fermanagh</option>
                  <option value="Fife">Fife</option>
                  <option value="Flintshire">Flintshire</option>
                  <option value="Gateshead">Gateshead</option>
                  <option value="Glasgow City">Glasgow City</option>
                  <option value="Gloucestershire">Gloucestershire</option>
                  <option value="Greenwich">Greenwich</option>
                  <option value="Gwynedd">Gwynedd</option>
                  <option value="Hackney">Hackney</option>
                  <option value="Halton">Halton</option>
                  <option value="Hammersmith and Fulham"
                    >Hammersmith and Fulham</option
                  >
                  <option value="Hampshire">Hampshire</option>
                  <option value="Haringey">Haringey</option>
                  <option value="Harrow">Harrow</option>
                  <option value="Hartlepool">Hartlepool</option>
                  <option value="Havering">Havering</option>
                  <option value="Hertfordshire">Hertfordshire</option>
                  <option value="Highland">Highland</option>
                  <option value="Hillingdon">Hillingdon</option>
                  <option value="Hounslow">Hounslow</option>
                  <option value="Inverclyde">Inverclyde</option>
                  <option value="Isle of Anglesey">Isle of Anglesey</option>
                  <option value="Isle of Wight">Isle of Wight</option>
                  <option value="Islington">Islington</option>
                  <option value="Kensington and Chelsea"
                    >Kensington and Chelsea</option
                  >
                  <option value="Kent">Kent</option>
                  <option value="Kingston upon Hull">Kingston upon Hull</option>
                  <option value="Kingston upon Thames"
                    >Kingston upon Thames</option
                  >
                  <option value="Kirklees">Kirklees</option>
                  <option value="Knowsley">Knowsley</option>
                  <option value="Lambeth">Lambeth</option>
                  <option value="Lancashire">Lancashire</option>
                  <option value="Larne">Larne</option>
                  <option value="Leeds">Leeds</option>
                  <option value="Leicester, City of">Leicester, City of</option>
                  <option value="Leicestershire">Leicestershire</option>
                  <option value="Lewisham">Lewisham</option>
                  <option value="Limavady">Limavady</option>
                  <option value="Lincolnshire">Lincolnshire</option>
                  <option value="Lisburn">Lisburn</option>
                  <option value="Liverpool">Liverpool</option>
                  <option value="London, City of">London, City of</option>
                  <option value="Luton">Luton</option>
                  <option value="Magherafelt">Magherafelt</option>
                  <option value="Manchester, City of"
                    >Manchester, City of</option
                  >
                  <option value="Medway">Medway</option>
                  <option value="Merthyr Tydfil">Merthyr Tydfil</option>
                  <option value="Merton">Merton</option>
                  <option value="Middlesbrough">Middlesbrough</option>
                  <option value="Midlothian">Midlothian</option>
                  <option value="Milton Keynes">Milton Keynes</option>
                  <option value="Monmouthshire">Monmouthshire</option>
                  <option value="Moray">Moray</option>
                  <option value="Moyle">Moyle</option>
                  <option value="Neath Port Talbot">Neath Port Talbot</option>
                  <option value="Newcastle upon Tyne"
                    >Newcastle upon Tyne</option
                  >
                  <option value="Newham">Newham</option>
                  <option value="Newport">Newport</option>
                  <option value="Newry and Mourne">Newry and Mourne</option>
                  <option value="Newtownabbey">Newtownabbey</option>
                  <option value="Norfolk" selected>Norfolk</option>
                  <option value="Northamptonshire">Northamptonshire</option>
                  <option value="North Ayrshire">North Ayrshire</option>
                  <option value="North Down">North Down</option>
                  <option value="North East Lincolnshire"
                    >North East Lincolnshire</option
                  >
                  <option value="North Lanarkshire">North Lanarkshire</option>
                  <option value="North Lincolnshire">North Lincolnshire</option>
                  <option value="North Somerset">North Somerset</option>
                  <option value="North Tyneside">North Tyneside</option>
                  <option value="Northumberland">Northumberland</option>
                  <option value="North Yorkshire">North Yorkshire</option>
                  <option value="Nottingham, City of"
                    >Nottingham, City of</option
                  >
                  <option value="Nottinghamshire">Nottinghamshire</option>
                  <option value="Oldham">Oldham</option>
                  <option value="Omagh">Omagh</option>
                  <option value="Orkney Islands">Orkney Islands</option>
                  <option value="Outer Hebrides">Outer Hebrides</option>
                  <option value="Oxfordshire">Oxfordshire</option>
                  <option value="Pembrokeshire">Pembrokeshire</option>
                  <option value="Perth and Kinross">Perth and Kinross</option>
                  <option value="Peterborough, City of"
                    >Peterborough, City of</option
                  >
                  <option value="Plymouth, City of">Plymouth, City of</option>
                  <option value="Poole">Poole</option>
                  <option value="Portsmouth, City of"
                    >Portsmouth, City of</option
                  >
                  <option value="Powys">Powys</option>
                  <option value="Reading">Reading</option>
                  <option value="Redbridge">Redbridge</option>
                  <option value="Redcar and Cleveland"
                    >Redcar and Cleveland</option
                  >
                  <option value="Renfrewshire">Renfrewshire</option>
                  <option value="Rhondda Cynon Taf">Rhondda Cynon Taf</option>
                  <option value="Richmond upon Thames"
                    >Richmond upon Thames</option
                  >
                  <option value="Rochdale">Rochdale</option>
                  <option value="Rotherham">Rotherham</option>
                  <option value="Rutland">Rutland</option>
                  <option value="Salford, City of">Salford, City of</option>
                  <option value="Sandwell">Sandwell</option>
                  <option value="Scottish Borders">Scottish Borders</option>
                  <option value="Sefton">Sefton</option>
                  <option value="Sheffield">Sheffield</option>
                  <option value="Shetland Islands">Shetland Islands</option>
                  <option value="Shropshire">Shropshire</option>
                  <option value="Slough">Slough</option>
                  <option value="Solihull">Solihull</option>
                  <option value="Somerset">Somerset</option>
                  <option value="Southampton, City of"
                    >Southampton, City of</option
                  >
                  <option value="South Ayrshire">South Ayrshire</option>
                  <option value="Southend-on-Sea">Southend-on-Sea</option>
                  <option value="South Gloucestershire"
                    >South Gloucestershire</option
                  >
                  <option value="South Lanarkshire">South Lanarkshire</option>
                  <option value="South Tyneside">South Tyneside</option>
                  <option value="Southwark">Southwark</option>
                  <option value="Staffordshire">Staffordshire</option>
                  <option value="St. Helens">St. Helens</option>
                  <option value="Stirling">Stirling</option>
                  <option value="Stockport">Stockport</option>
                  <option value="Stockton-on-Tees">Stockton-on-Tees</option>
                  <option value="Stoke-on-Trent, City of"
                    >Stoke-on-Trent, City of</option
                  >
                  <option value="Strabane">Strabane</option>
                  <option value="Suffolk">Suffolk</option>
                  <option value="Sunderland">Sunderland</option>
                  <option value="Surrey">Surrey</option>
                  <option value="Sutton">Sutton</option>
                  <option value="Swansea">Swansea</option>
                  <option value="Swindon">Swindon</option>
                  <option value="Tameside">Tameside</option>
                  <option value="Telford and Wrekin">Telford and Wrekin</option>
                  <option value="The City of Brighton and Hove,"
                    >The City of Brighton and Hove,</option
                  >
                  <option value="Thurrock">Thurrock</option>
                  <option value="Torbay">Torbay</option>
                  <option value="Torfaen">Torfaen</option>
                  <option value="Tower Hamlets">Tower Hamlets</option>
                  <option value="Trafford">Trafford</option>
                  <option value="Vale of Glamorgan">Vale of Glamorgan</option>
                  <option value="Wakefield">Wakefield</option>
                  <option value="Walsall">Walsall</option>
                  <option value="Waltham Forest">Waltham Forest</option>
                  <option value="Wandsworth">Wandsworth</option>
                  <option value="Warrington">Warrington</option>
                  <option value="Warwickshire">Warwickshire</option>
                  <option value="West Berkshire">West Berkshire</option>
                  <option value="West Dunbartonshire"
                    >West Dunbartonshire</option
                  >
                  <option value="West Lothian">West Lothian</option>
                  <option value="Westminster, City of"
                    >Westminster, City of</option
                  >
                  <option value="West Sussex">West Sussex</option>
                  <option value="Wigan">Wigan</option>
                  <option value="Wiltshire">Wiltshire</option>
                  <option value="Windsor and Maidenhead"
                    >Windsor and Maidenhead</option
                  >
                  <option value="Wirral">Wirral</option>
                  <option value="Wokingham">Wokingham</option>
                  <option value="Wolverhampton, City of"
                    >Wolverhampton, City of</option
                  >
                  <option value="Worcestershire">Worcestershire</option>
                  <option value="Wrexham">Wrexham</option>
                  <option value="York">York</option>
                </Field>
                <ErrorMessage name="province" class="error-feedback" />
              </div>
            </div>
          </div>
          <div class="flex-row">
            <div class="col1">
              <div class="inputGroup">
                <label for="password">Choose a Password:</label>
                <Field name="password" type="password" />
                <ErrorMessage name="password" class="error-feedback" />
              </div>
            </div>
            <div class="col1">
              <div class="inputGroup">
                <label for="confirmPassword">Confirm Password:</label>
                <Field name="confirmPassword" type="password" />
                <ErrorMessage name="confirmPassword" class="error-feedback" />
              </div>
            </div>
          </div>
          <div class="flex-row pb2">
            <button class="purple-button" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Continue</span>
            </button>
            <button type="reset" class="grey-button">Cancel</button>
          </div>
          <div v-if="message" class="flex-row pb">
            <div
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'"
              role="alert"
            >
              {{ message }}
            </div>
          </div>
        </div>
      </Form>
      <Form
        @submit="createExistAccount"
        :validation-schema="schema"
        :initial-values="formValues"
        v-if="userStatus === 'exists'"
      >
        <div class="card">
          <div class="flex-row">
            <div class="col1">
              <div class="inputGroup">
                <label for="accountName">Account Name:</label>
                <Field name="accountName" type="text" disabled />
                <ErrorMessage name="accountName" class="error-feedback" />
              </div>
            </div>
            <div class="col1">
              <div class="inputGroup">
                <label for="email">Email:</label>
                <Field name="email" type="email" disabled />
                <ErrorMessage name="email" class="error-feedback" />
                <a href="/new-account"><small>Change Email</small></a>
              </div>
            </div>
          </div>
          <div class="flex-row">
            <div class="col1">
              <div class="inputGroup">
                <label for="password">Password:</label>
                <Field name="password" type="password" />
                <ErrorMessage name="password" class="error-feedback" />
              </div>
            </div>
            <div class="col1">
              <div class="inputGroup">
                <label for="confirmPassword">Confirm Password:</label>
                <Field name="confirmPassword" type="password" />
                <ErrorMessage name="confirmPassword" class="error-feedback" />
              </div>
            </div>
          </div>
          <div class="flex-row pb2">
            <button class="purple-button" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Continue</span>
            </button>
            <button type="reset" class="grey-button">Cancel</button>
          </div>
          <div v-if="message" class="flex-row pb">
            <div
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'"
              role="alert"
            >
              {{ message }}
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AuthService from "../services/auth.service";

export default {
  name: "NewAccount",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      userEmail: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      tags: yup.string().required("Customer type is needed!"),
      firstName: yup.string().required("First Name is required!"),
      phone: yup
        .string()
        .required("Phone Number is required!")
        .min(5, "Must be minimum 10 characters!"),
      address1: yup.string().required("Address is required!"),
      province: yup.string().required("County is required!"),
      postalCode: yup.string().required("Postal Code is required!"),
      city: yup.string().required("City is required!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!"),
      confirmPassword: yup
        .string()
        .required("Password is required!")
        .min(6, "Must be at least 6 characters!")
        .max(40, "Must be maximum 40 characters!")
        .oneOf([yup.ref("password")], "Passwords must match"),
    });

    var formValues = {
      email: "email",
      accountName: "accountName",
    };
    return {
      successful: false,
      loading: false,
      message: "",
      schema,
      formValues,
      hardwareAssets: "",
      ticketDetails: "",
      userStatus: "registered",
    };
  },
  methods: {
    async checkUserStatus(user) {
      try {
        this.successful = true;
        this.loading = true;
        let account = await AuthService.getUserByEmail(user);
        this.formValues.accountName = account.data.AccountName;
        this.formValues.email = user.userEmail;
        this.loading = false;
        this.userStatus = "exists";
      } catch (error) {
        this.successful = false;
        this.loading = false;
        this.formValues.email = user.userEmail;
        this.userStatus = "unregistered";
      }
    },
    async createExistAccount(user) {
      try {
        this.successful = true;
        this.loading = true;
        await AuthService.createExistAccount(user);
        this.$router.push({
          name: "login",
        });
        this.loading = false;
      } catch (error) {
        this.successful = false;
        this.loading = false;
        this.message = "You already have a Account try resetting password!";
      }
    },
    async createAccount(user) {
      try {
        this.successful = true;
        this.loading = true;
        await AuthService.createAccount(user);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.successful = false;
        this.loading = false;
        this.message = error.message;
      }
    },
  },
};
</script>

<style scoped>
a small {
  text-decoration: none;
  color: #7367f0;
}

a:hover {
  text-decoration: none;
}
.new-account {
  text-align: center;
  padding: 1rem 0;
  border-top: solid 1px #d8d6de;
}
.new-account a {
  font-weight: 500;
  color: #7367f0;
  text-decoration: none;
  font-size: 0.9rem;
}

.flex-rows a {
  font-family: "poppins";
  color: #7367f0 !important;
  float: right;
  text-decoration: none;
  display: inline;
}

.alert {
  font-weight: 600;
  font-size: 1rem;
  padding: 0.71rem 1rem;
  margin-bottom: 0;
  margin-top: 0.71rem;
}

.alert-danger {
  background-color: #fbdddd;
  border-color: #f9cfcf;
  box-shadow: rgb(234 84 85 / 40%) 0px 6px 15px -7px;
  color: #ea5455;
}

.btn-primary {
  border-color: #7367f0 !important;
  background-color: #7367f0 !important;
  color: #fff !important;
  font-family: "poppins";
  font-size: 0.9rem;
}

.company {
  text-align: center;
}

.company img {
  margin-bottom: 1.5rem !important;
}

button {
  margin-right: 1rem;
  width: 130px;
}

.login h4 {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.5rem !important;
  line-height: 1.2;
  color: #5e5873;
  font-family: "poppins";
  text-align: center;
}

.login p {
  margin-bottom: 2.5rem !important;
  font-family: "poppins";
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  text-align: center;
}

.login form p {
  margin-bottom: 1.5rem !important;
  font-family: "poppins";
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  text-align: left;
}

#profile-img {
  width: 150px;
}

.flex-row {
  margin-bottom: 1rem;
}

.col1 {
  width: 50%;
  max-width: 50%;
  margin-right: 0.5rem;
}

.col1:first-child {
  margin-right: 1.5rem;
}

.login.registered {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 400px;
  flex-direction: column;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  background-color: white;
}

.login.unregistered,
.login.exists {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 900px;
  flex-direction: column;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  background-color: white;
}

@media screen and (max-width: 925px) {
  .login.registered {
    width: 350px;
  }
  .login.unregistered,
  .login.exists {
    width: 650px;
  }
}

@media screen and (max-width: 400px) {
  .login.registered {
    width: calc(100% - 1.5em);
  }
}

@media screen and (max-width: 700px) {
  .flex-row {
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
  .login.registered {
    padding: 0.7rem;
  }

  .login.unregistered,
  .login.exists {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 450px;
    margin-top: 1.5rem;
  }

  .col1 {
    width: 100%;
    max-width: 100%;
    margin-right: 0.5rem;
  }

  .col1 {
    margin-right: 0rem;
  }

  .col1:first-child {
    margin-right: 0rem;
  }

  button {
    width: 100%;
    margin: 0;
    margin-top: 1rem;
  }

  .purple-button {
    width: 100% !important;
    margin: 0 !important;
  }

  .login.unregistered {
    padding: 0.7rem;
  }
}
@media screen and (max-width: 500px) {
  .login.unregistered,
  .login.exists {
    width: 400px;
  }
}

@media screen and (max-width: 425px) {
  .login.unregistered,
  .login.exists {
    width: 350px;
    margin-top: 1rem;
  }
}

.error-feedback {
  color: #ea5455;
  font-size: 0.8rem;
  font-family: "poppins";
  font-weight: 500;
}

label {
  color: #5e5873;
  font-size: 0.9rem;
  font-family: "poppins";
  font-weight: 500;
  margin-bottom: 0.2857rem;
  display: block;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.438rem 0.438rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.card {
  background-color: white;
  padding: 15px 0px 15px 0px;
  margin: auto;
  border: 0px;
  box-shadow: 0 0 0 0 rgb(34 41 47 / 10%);
  width: 100%;
}

.pb2 button:first-child {
  margin-top: 0rem;
  margin-right: 1rem;
  width: 190px;
}

input,
textarea {
  overflow: hidden;
  padding: 0.35rem 0.5rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  margin-right: 1rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  width: 100%;
}

select {
  color: #6e6b7b;
  background-color: #fff;
  cursor: default;
  margin: 0em;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 0.35rem 0.2rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  font-family: "poppins";
  width: 100%;
}

input:focus {
  box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;
  background-color: #fff !important;
  border-color: #7367f0 !important;
}

.btn-block {
  width: 100%;
}
@media screen and (max-width: 500px) {
  .login {
    padding: 0.5rem !important;
  }
}

.inputGroup {
  padding-bottom: 0.5rem;
}
</style>
